.englishContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.englishRecordConent {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.englishRecordCalendar {
    width: 350px;
    overflow: hidden;
    transition: 0.5s;
    align-items: center;
    justify-content: space-between;
}


.englishQuestionConent {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 24px;
    margin-right: 24px;
    align-items: center;
    justify-content: center;
    padding-bottom: 48px;
}

.englishLeftDistance {
    margin-left: 20px;
}

.englishCard {
    font-weight: 300;
    text-align: left;
    white-space: normal;
    word-break: break-word;
    color: var(--textTwo);
    transition: 0.5s;
    font-size: x-large;
}

.cardBg {
    background-color: var(--primaryTwo);
    color: var(--textTwo);
    transition: 0.5s;
    margin-left: 10px;
    margin-bottom: 5px;
}

.answerColor {
    color: var(--textTwo);
    transition: 0.5s;
}

.answerFont {
    font-weight: 300;
    font-size: large;
}

.answerResult {
    margin-top: 24px;
}

.dateCell {
    display: flex;
    flex-direction: column; 
    
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    margin-left: 10px;
}
.dateCellColor {
    background-color: var(--bgFourth);
}

.answerCardDiv {
    margin-top: 30px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
   
}

.answerCardButton {
    font-size: 24px;
    color: var(--textTwo);
    background-color: var(--bgTwo);
    border-radius: 30%;
    width: 48px;
    height: 48px;
    margin-left: 20px;
    line-height: 48px;
    text-align: center;
}

.answerCardButtonWithValue {
    font-size: 24px;
    color: var(--textTwo);
    background-color: rgb(103, 165, 212);
    border-radius: 30%;
    width: 48px;
    height: 48px;
    margin-left: 20px;
    line-height: 48px;
    text-align: center;
}

.submitButton {
    color: var(--textTwo);
    transition: 0.5s;
    background-color: var(--bgThree);
    font-size: large;
    margin-top: 20px;
    width: 64px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    border-radius: 5%;

}

.enableClick {
    background-color: var(--bgFourth);
}

.submitButtonBox {
    align-items: center;
    display: flex;
    flex-direction: column;
}