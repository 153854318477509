:root {
    /* 背景色随便用 */
    --primary-100-light:#d4eaf7;
    /* 小组件背景 */
    --primary-200-light:#b6ccd8;
    /* header最大的字体 最黑的 */
    --primary-300-light:#3b3c3d;
    /* 大组件 */
    --accent-100-light:#71c4ef;
    /* 小组件 */
    --accent-200-light:#00668c;
    /* 一级title */
    --text-100-light:#1d1c1c;
    /* 二级title */
    --text-200-light:#313d44;
    /* 最底层的背景 */
    --bg-100-light:#fffefb; 
    /* 组件的背景 */
    --bg-200-light:#f5f4f1;
    /* navigationbar的背景 */
    --bg-300-light:#cccbc8;
    /* 超小组件亮色 */
    --bg-400-light: rgb(74, 185, 74);

     /* 背景色随便用 */
     --primary-100-dark:#1F3A5F;
      /* 小组件背景 */
    --primary-200-dark:#4d648d;
    /* header最大的字体 最黑的 */
    --primary-300-dark:#acc2ef; 
    /* 大组件 */
    --accent-100-dark:#3D5A80;
    /* 小组件 */
    --accent-200-dark:#cee8ff;
     /* 一级title */
    --text-100-dark:#FFFFFF;
     /* 二级title */
    --text-200-dark:#e0e0e0;
     /* 最底层的背景 */
    --bg-100-dark:#0F1C2E;
    /* 组件的背景 */
    --bg-200-dark:#1f2b3e;
     /* navigationbar的背景 */
    --bg-300-dark:#374357;
     /* 超小组件亮色 */
     --bg-400-dark: rgb(8, 177, 8);
}
   
.dark-mode {
    --primaryOne:var(--primary-100-dark);
    --primaryTwo:var(--primary-200-dark);
    --primaryThree:var(--primary-300-dark);

    --accentOne:var(--accent-100-dark);
    --accentTwo:var(--accent-200-dark);

    --textOne:var(--text-100-dark);
    --textTwo:var(--text-200-dark);

    --bgOne:var(--bg-100-dark);
    --bgTwo:var(--bg-200-dark);
    --bgThree:var(--bg-300-dark);
    --bgFourth:var(--bg-400-dark);
}

.light-mode {
    --primaryOne:var(--primary-100-light);
    --primaryTwo:var(--primary-200-light);
    --primaryThree:var(--primary-300-light);

    --accentOne:var(--accent-100-light);
    --accentTwo:var(--accent-200-light);

    --textOne:var(--text-100-light);
    --textTwo:var(--text-200-light);

    --bgOne:var(--bg-100-light);
    --bgTwo:var(--bg-200-light);
    --bgThree:var(--bg-300-light);
    --bgFourth:var(--bg-400-light);

}

html, body, #root {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
}

.textFontWeightForHeader {
    font-weight:250;
}

.textFontSizeForHeader {
    font-size: 24px;
}
/*
    --primary-100:#d4eaf7;
    --primary-200:#b6ccd8;
    --primary-300:#3b3c3d;
    --accent-100:#71c4ef;
    --accent-200:#00668c;
    --text-100:#1d1c1c;
    --text-200:#313d44;
    --bg-100:#fffefb;
    --bg-200:#f5f4f1;
    --bg-300:#cccbc8;
      shadow
*/

/*
       --primary-100:#1F3A5F;
    --primary-200:#4d648d;
    --primary-300:#acc2ef;
    --accent-100:#3D5A80;
    --accent-200:#cee8ff;
    --text-100:#FFFFFF;
    --text-200:#e0e0e0;
    --bg-100:#0F1C2E;
    --bg-200:#1f2b3e;
    --bg-300:#374357;
      
      dark
*/