.loginModal {
    z-index: 1001
}
.rootDiv {
    background-color: var(--bgOne);
    height: 100%;
    transition: 0.5s;
    overflow: scroll;
}
.content {
    display: flex;
    flex-direction : column;
    padding-bottom: 30px;
    background-color: var(--bgOne);
    color: var(--textsentence);
    transition: 0.5s;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    
}

.content2 {
    display: flex;
    flex-direction : column;
    padding-bottom: 30px;
    background-color: var(--bgOne);
    color: var(--textsentence);
    transition: 0.5s;
    height: 100%;
    width: 100%;
    align-items: center;
}

.dividerLine {
    color: var(--textTwo);
    font-size: 24px;
}

.belowNavigationText {
    display: flex;
    color: var(--textTwo);
    width: 100%;
    background-color: var(--bgTwo);
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 100%;
    font-size: x-large;
}