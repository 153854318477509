.wholeApp {
    display: flex;
    flex-direction: column;
    width: 100%;

}

.cardForThings {
    margin-left: 36px;
    margin-right: 36px;
    margin-top: 36px;
    font-size: large;
}
.cardThingsBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
   
    padding-bottom: 36px;
    /* background-color: red; */

    
}
.cardContentLines {
    max-width: 400px;
    white-space: nowrap; 
    text-overflow:ellipsis;
    overflow: hidden;
}



.userInteractionSpace{
    font-size: x-large;
}