.registrationBox {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-items: center;
}

.bgImage {
    width: 60%;
    height: 100%;
    margin-left: 0px;
    background-image: url('./../../../public/HK.JPG');
    /* background-position-x: 40%;
    background-position-y:40%; */
    background-repeat: no-repeat;
    background-size:cover;
    z-index: 1;
   
}

.formLayout {
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 100%;
    align-items: center;
    justify-content: center;
    margin-left: 48px;
    z-index: 1;
}

.formItemSpan {
    margin-top: 48px;
}

.resultDiv {
    z-index: 2;
    background-color:azure;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid gainsboro;
}

.showOrHidden {
    display: none;
}