.navigation-bar {
    width: 100%;
    position: fixed;
    top: 0px;
    display: flex;
    flex-direction: row;
    line-height: 40px;
    height: 40px;
    align-items:center;
    /* justify-content: flex-end; */
    font-size: 24px;
    color : var(--textOne) ;
    background-color: var(--bgThree);
    border-bottom: 1px solid var(--seperateLine);
    z-index: 100;
}

.navigation-bar div {
    margin-left: 20px;
}

.webTitle{
    margin-left: 40px;
}

.englishRecordText {
    display: flex;
    flex-direction: column;
    align-items: center;

}
.registerAndLogin {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    align-items: center;
}

.navigationTextHeader {
    color:var(--textOne)
}

.navigationTextHeader:hover {
    color:var(--accentTwo) !important; 
}

.navigationSun {
    right: 24px;
    height: 40px;
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 40px;
}

.logOutIcon {
    margin-left: 8px;
}

.logOutUserName {
   text-align :center;
   width:100%;
}